<template>
 <div class="px-4 py-16 sm:px-6 sm:py-24 xl:grid xl:place-items-top lg:px-8">
  <main class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
   <div class="text-center">
    <p class="text-base font-semibold encom_primary_text">
     {{ this.$route.query.status }}
    </p>
    <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
     {{ this.$route.query.msg }}
    </h1>
    <p class="mt-6 text-base leading-7 text-gray-600">
     {{ $t("errorExpertStats") }}
    </p>
    <div class="mt-10 flex items-center justify-center gap-x-6">
     <a
      href="/"
      class="rounded-md encom_primary encom_primary_hover px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
     >
      {{ $t("goBackHome") }}
     </a>
     <a href="mailto:developers@cx-engine.com" class="text-sm font-semibold text-gray-900">
      {{ $t("contactDevTeam") }}

      <span aria-hidden="true">&rarr;</span></a
     >
    </div>
   </div>
  </main>
 </div>
</template>

<script>
export default {
 name: "Error",
 props: ["msg", "status"],
};
</script>

<style></style>
